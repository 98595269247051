html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
strong {
  font-weight: bold;
}
em,
i {
  font-style: italic;
}
a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  font-size: 100%;
  text-decoration: none;
  background: transparent;
}
:focus {
  outline: none;
}
textarea,
select {
  vertical-align: middle;
  appearance: none;
  outline: 0;
}
* {
  box-sizing: border-box;
}

input,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
  font-weight: normal;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-family: proxima-nova, sans-serif;
}

@media all and (max-width: 768px) {
  .hideMobile {
    display: none !important;
  }
}

@media all and (max-width: 968px) {
  .hideTablet {
    display: none !important;
  }
}
